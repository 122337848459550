import * as THREE from 'three';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import fontFile from './fonts/optimer_bold.typeface.json';
import helvetiker from './fonts/helvetiker_regular.typeface.json';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import ilijan from './model/ilijan-3d-model.glb'; 
import diffuseTexture from './textures/decal-diffuse.png';
import normalTexture from  './textures/decal-normal.jpg';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';



THREE.Cache.enabled = true;

let container;

let camera, cameraTarget, scene, renderer;

let group, textMesh1, textMesh2, textGeo, materials;
let paintScene;

let mesh;
let raycaster;
let line;

const intersection = {
    intersects: false,
    point: new THREE.Vector3(),
    normal: new THREE.Vector3()
};
const mouse = new THREE.Vector2();
const intersects = [];

const textureLoader = new THREE.TextureLoader();
const decalDiffuse = textureLoader.load(diffuseTexture);
const decalNormal = textureLoader.load(normalTexture);

const decalMaterial = new THREE.MeshPhongMaterial( {
    specular: 0x444444,
    map: decalDiffuse,
    normalMap: decalNormal,
    normalScale: new THREE.Vector2( 1, 1 ),
    shininess: 30,
    transparent: true,
    depthTest: true,
    depthWrite: false,
    polygonOffset: true,
    polygonOffsetFactor: - 4,
    wireframe: false
} );

const decals = [];
let mouseHelper;
const position = new THREE.Vector3();
const orientation = new THREE.Euler();
const size = new THREE.Vector3( 50, 50, 50 );


let text = 'ilijanova\noprostajka\npicerija\nmedika\nsubota\n26.11. 20h',
font = undefined;

const height = 40;


let targetRotation = 0;
let targetRotationOnPointerDown = 0;

let pointerX = 0;
let pointerXOnPointerDown = 0;

let windowHalfX = window.innerWidth / 2;

let moved = false;

init();
animate();

function decimalToHex( d ) {

    let hex = Number( d ).toString( 16 );
    hex = '000000'.substring( 0, 6 - hex.length ) + hex;
    return hex.toUpperCase();

}

function init() {

    container = document.createElement( 'div' );
    document.body.appendChild( container );

    // CAMERA

    camera = new THREE.PerspectiveCamera( 30, window.innerWidth / window.innerHeight, 1, 1500 );
    camera.position.set( -200, 400, 700 );

    camera.lookAt(new THREE.Vector3( 0, 150, 0 ));

    // SCENE

    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x000000 );
    scene.fog = new THREE.Fog( 0x000000, 250, 1400 );

    paintScene = new THREE.Scene();

    // LIGHTS

    const dirLight = new THREE.DirectionalLight( 0xffffff, 0.125 );
    dirLight.position.set( 0, 0, 1 ).normalize();
    scene.add( dirLight );

    const pointLight1 = new THREE.PointLight( 0xffffff, 1.5 );
    pointLight1.position.set( 0, 100, 90 );
    scene.add( pointLight1 );

    const pointLight2 = new THREE.PointLight( 0xffffff, 1.5 );
    pointLight2.position.set( -50, 60, -110 );
    scene.add( pointLight2 );

    materials = [
        new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true } ), // front
        new THREE.MeshPhongMaterial( { color: 0xffffff } ) // side
    ];

    const hue = Math.random();
    materials[0].color.setHSL(hue, 1, 0.5);
    materials[1].color.setHSL(hue, 1, 0.5);

    group = new THREE.Group();
    group.position.y = 100;

    scene.add( group );

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints( [ new THREE.Vector3(), new THREE.Vector3() ] );

    line = new THREE.Line( geometry, new THREE.LineBasicMaterial() );
    scene.add( line );

    loadFont();
    loadIlijan();

    raycaster = new THREE.Raycaster();

    mouseHelper = new THREE.Mesh( new THREE.BoxGeometry( 1, 1, 10 ), new THREE.MeshNormalMaterial() );
    mouseHelper.visible = false;
    scene.add( mouseHelper );

    // RENDERER

    renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    container.appendChild( renderer.domElement );

    const controls = new OrbitControls(camera, renderer.domElement);

    // EVENTS

    container.style.touchAction = 'none';
    container.addEventListener( 'pointerdown', onPointerDown );
    container.addEventListener( 'change', function () {
        moved = true;
    } );

    window.addEventListener( 'resize', onWindowResize );
}

function onWindowResize() {

    windowHalfX = window.innerWidth / 2;

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );
}


function loadFont() {

    const loader = new FontLoader();
    loader.load(helvetiker, function ( response ) {

        font = response;

        refreshText();

    } );

}

function loadIlijan() {

    // const loader = new GLTFLoader();

    // loader.load(ilijan, function ( gltf ) {
    //     const { scene } = gltf;

    //     // mesh = scene.children[0];

    //     group.add( scene );
    //     scene.scale.set( 300, 300, 300 );
    //     scene.rotateY(- Math.PI / 2.3);
    //     scene.translateOnAxis(new THREE.Vector3(0.5, -2, -0.5), 100);
        

    // } );

}

function createText() {

    textGeo = new TextGeometry( text, {

        font: font,

        size: 40,
        height: 40,
        curveSegments: 4,

        bevelThickness: 2,
        bevelSize: 1.5,
        bevelEnabled: true

    } );

    textGeo.computeBoundingBox();

    const centerOffset = - 0.5 * ( textGeo.boundingBox.max.x - textGeo.boundingBox.min.x );

    textMesh1 = new THREE.Mesh( textGeo, materials );

    textMesh1.position.x = centerOffset;
    textMesh1.position.y = 50;
    textMesh1.position.z = 0;

    textMesh1.rotation.x = 0;
    textMesh1.rotation.y = Math.PI * 2;

    group.add( textMesh1 );
    group.add( paintScene );

    paintScene.position.y = -100;

    mesh = textMesh1;
}

function shoot() {

    position.copy( intersection.point );
    orientation.copy( mouseHelper.rotation );

    // if ( params.rotate ) orientation.z = Math.random() * 2 * Math.PI;

    // const scale = params.minScale + Math.random() * ( params.maxScale - params.minScale );
    // size.set( scale, scale, scale );

    const material = decalMaterial.clone();
    material.color.setHex( Math.random() * 0xffffff );

    const m = new THREE.Mesh( new DecalGeometry( mesh, position, orientation, size ), material );

    decals.push( m );
    paintScene.add( m );

}

function checkIntersection( x, y ) {

    if ( mesh === undefined ) return;

    mouse.x = ( x / window.innerWidth ) * 2 - 1;
    mouse.y = - ( y / window.innerHeight ) * 2 + 1;

    raycaster.setFromCamera( mouse, camera );
    raycaster.intersectObject( mesh, false, intersects );

    if ( intersects.length > 0 ) {

        const p = intersects[ 0 ].point;
        mouseHelper.position.copy( p );
        intersection.point.copy( p );

        const n = intersects[ 0 ].face.normal.clone();
        n.transformDirection( mesh.matrixWorld );
        n.multiplyScalar( 10 );
        n.add( intersects[ 0 ].point );

        intersection.normal.copy( intersects[ 0 ].face.normal );
        mouseHelper.lookAt( n );

        const positions = line.geometry.attributes.position;
        positions.setXYZ( 0, p.x, p.y, p.z );
        positions.setXYZ( 1, n.x, n.y, n.z );
        positions.needsUpdate = true;

        intersection.intersects = true;

        intersects.length = 0;

    } else {

        intersection.intersects = false;

    }
}

function refreshText() {
    createText();
}

function onPointerDown( event ) {

    if ( event.isPrimary === false ) return;

    // pointerXOnPointerDown = event.clientX - windowHalfX;
    // targetRotationOnPointerDown = targetRotation;

    document.addEventListener( 'pointermove', onPointerMove );
    document.addEventListener( 'pointerup', onPointerUp );

    moved = false;
}

function onPointerMove( event ) {

    if ( event.isPrimary === false ) return;

    moved = true;

    // pointerX = event.clientX - windowHalfX;

    // targetRotation = targetRotationOnPointerDown + ( pointerX - pointerXOnPointerDown ) * 0.02;

    checkIntersection( event.clientX, event.clientY );
}



function onPointerUp() {

    if ( event.isPrimary === false ) return;

    if ( moved === false ) {
        checkIntersection( event.clientX, event.clientY );
        if ( intersection.intersects ) shoot();
    }

    document.removeEventListener( 'pointermove', onPointerMove );
    document.removeEventListener( 'pointerup', onPointerUp );

}

//

function animate() {

    requestAnimationFrame( animate );

    render();
}

function render() {

    // group.rotation.y += ( targetRotation - group.rotation.y ) * 0.05;

    // camera.lookAt( cameraTarget );

    renderer.clear();
    renderer.render( scene, camera );

}